<!--  -->
<template>
  <div class="main_content">
    <Header :navItems="$store.state.navItems" :key="$route.path"></Header>
    <div class="main_body">
      <NavBar :navItems="$store.state.navItems"></NavBar>
      <div class="body_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header/index";
import NavBar from "../../components/NavBar/index";
import { getSitesList } from "../../api/main";
export default {
  components: {
    Header,
    NavBar
  },
  created () {
    if (!this.$ws.readyState()) {
      this.$ws.create()
    }
    this.$store.commit('getNavItems') // 获取菜单列表
  }
};
</script>
<style lang="scss" scoped>
.main_content {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  .main_body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(980 / 1080 * 100vh);
    background: rgb(239, 242, 245);
    .body_right {
      padding-top: calc(15 / 1080 * 100vh);
      padding-left: calc(16 / 1920 * 100vw);
      padding-right: calc(24 / 1920 * 100vw);
      width: calc(1609 / 1920 * 100vw);
      height: calc(990 / 1080 * 100vh);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // background: #e5ecf5;
      // border: 1px solid;
    }
  }
}
</style>
